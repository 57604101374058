/**
 * module accounts.js
 */

const accountName = (key, item) => {
  return fullName(item) || key
}
const fullName = item => {
  let name = `${item.first_name || ''} ${item.last_name || ''}`.trim()
  name = (name || (item.description || '')).trim()
  return name
}
export {
  accountName,
  fullName
}
