<template>
  <v-form
    v-model="valid"
    ref="form"
    class="mt-4"
    :key="item.username"
  >
    <v-row>
      <v-col>
        <v-text-field
          dense
          label="Nome"
          v-model="editedUser.first_name"
          prepend-icon="mdi-account-details"
        />
      </v-col>
      <v-col>
        <v-text-field
          dense
          label="Cognome"
          v-model="editedUser.last_name"
          prepend-icon="mdi-account-details"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          dense
          label="email"
          v-model="editedUser.email"
          prepend-icon="mdi-email"
          :rules="[rules.required, rules.email]"
        />
      </v-col>
      <v-col>
        <v-text-field
          dense
          label="Conferma email"
          v-model="editedUser.email2"
          prepend-icon="mdi-email"
          :rules="[rules.requiredConditional(checkMail2), rules.email, rules.sameAs(editedUser, 'email', 'Email')]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <vue-tel-input-vuetify
          dense
          ref="phone"
          tabindex="4"
          class="sso-tel-input"
          v-model="editedUser.phone"
          v-bind="phoneProps"
        >
          <template #prepend>
            <v-icon
              ref="phoneIcon"
              v-text="'mdi-cellphone-android'"
              :color="phoneProps.valid ? '' : 'error'"
            />
          </template>
        </vue-tel-input-vuetify>
      </v-col>
      <v-col>
        <vue-tel-input-vuetify
          dense
          ref="phone2"
          tabindex="5"
          class="sso-tel-input"
          v-model="editedUser.phone2"
          v-bind="phoneProps2"
        >
          <template #prepend>
            <v-icon
              ref="phoneIcon"
              v-text="'mdi-cellphone-android'"
              :color="phoneProps2.valid ? '' : 'error'"
            />
          </template>
        </vue-tel-input-vuetify>
        <v-col />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="mappablesHeaders"
          :items="mappables"
          v-model="editedUser.can_map"
          :single-select="singleSelect"
          show-select
          class="elevation-1"
          hide-default-header
          hide-default-footer
        />
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer />
      <v-btn
        small
        color="accent"
        :disabled="!isChanged"
        @click="cancel"
      >
        annulla
      </v-btn>
      <v-btn
        small
        color="primary"
        :disabled="!isChanged"
        @click="save"
      >
        Salva
      </v-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import rules from '@/utils/rules.js'
import isEqual from 'lodash/isEqual'
export default {
  props: {
    value: {
      type: Object,
      default: () => ({ __key: null })
    }
  },
  data () {
    return {
      rules,
      valid: false,
      editedUser: {},
      singleSelect: false,
      selected: [],
      mappables: [],
      mappablesHeaders: [
        { text: 'nome', value: 'name' },
        { text: 'descrizione', value: 'description' },
        { text: '', value: 'data-table-select' }
      ],
      phoneProps: {
        label: 'Telefono',
        name: 'phone',
        clearable: true,
        onlyCountries: ['IT'],
        defaultCountry: 'IT',
        validCharactersOnly: true,
        rules: [this.checkPhone],
        valid: true,
        mode: 'national',
        dropdownOptions: {
          disabledDialCode: true
        },
        inputOptions: {
          showDialCode: false
        }
      },
      phoneProps2: {
        label: 'Conferma telefono',
        name: 'phone2',
        clearable: true,
        onlyCountries: ['IT'],
        defaultCountry: 'IT',
        validCharactersOnly: true,
        rules: [this.checkPhone2],
        valid: true,
        mode: 'national',
        dropdownOptions: {
          disabledDialCode: true
        },
        inputOptions: {
          showDialCode: false
        }
      }
    }
  },
  computed: {
    isChanged () {
      return !isEqual(this.item, this.editedUser)
    },
    item: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    this.getMappables()
  },
  methods: {
    getMappables () {
      this.$http.get('/api/app_admin/app/mappable_apps/')
        .then(r => {
          this.mappables = r.data
        })
    },
    checkMail2 (value) {
      return this.item.email === this.editedUser.email
    },
    checkPhone (formattedNumber) {
      if (!formattedNumber) {
        this.phoneProps.valid = true
        return true
      }
      const phone = this.$refs?.phone.phoneObject || null
      if (phone) {
        this.phoneProps.valid = phone.isValid
        return phone.isValid || 'Numero non valido'
      }
      return true
    },
    checkPhone2 (formattedNumber) {
      if (this.item.phone === this.editedUser.phone) return true
      let valid = rules.requiredIf(this.editedUser, 'phone', 'Obbligatorio insieme al campo Telefono')(formattedNumber)
      if (valid === true) {
        valid = rules.sameAs(this.editedUser, 'phone', 'Telefono')(formattedNumber)
      }
      this.phoneProps2.valid = valid === true
      return valid
    },
    initUser () {
      this.editedUser = Object.assign({}, this.value)
    },
    async save () {
      if (this.$refs.form.validate()) {
        const save = await this.$dialog.confirm({
          title: 'Modifica utente',
          text: 'Salvare le modifiche ?',
          dark: true,
          type: 'warning',
          icon: 'mdi-alert',
          actions: {
            false: {
              outlined: true,
              small: true,
              color: 'primary',
              text: 'Annulla'
            },
            true: {
              outlined: true,
              color: 'warning',
              small: true,
              text: 'salva'
            }
          }
        })
        if (save) {
          const done = await this.$http.patch(`/api/app_admin/app/${this.editedUser.app_id}/aau/${this.editedUser.user_id}/`, this.editedUser)
            .then(r => {
              this.userData = r.data
              this.$msgInfo('Dati modificati con successo')
              return true
              /* this.editUser() */
            })
            .catch(e => {
              this.$msgError(e)
              return false
            })
          if (done) {
            delete this.editedUser.email2
            delete this.editedUser.phone2
            this.$emit('input', Object.assign({}, this.editedUser))
            this.initUser()
          }
        }
      }
    },
    cancel () {
      this.initUser()
    }
  },
  mounted () {
    this.initUser()
    global.udet = this
    console.log('Mounted')
  },
  watch: {
    'item' () {
      this.initUser()
    }
  }
}
</script>
<style lang="scss">
.sso-tel-input {
  .country-code {
    display: none;
  }
}
</style>
