<template>
  <v-card
    v-if="userData"
    color="white"
  >
    <sso-card-header
      :title="title"
      :sub-title="userData.email"
      avatar
      color="info"
      :gravatar="userData.email"
    />
    <v-form
      class="app-user-form"
      v-model="valid"
      style="width: 100%;"
    >
      <v-row>
        <v-col cols="3">
          <v-list
            dense
            shaped
          >
            <v-list-item-group
              color="primary"
              v-model="selectedAccountIndex"
              mandatory
              :key="userChanged"
            >
              <v-subheader>Dati utente</v-subheader>
              <v-list-item
                @click="editUser()"
              >
                <v-list-item-avatar>
                  <v-gravatar
                    :email="userData.email || userData.mail || ''"
                    alt="Nobody"
                    :size="80"
                    default-img="mm"
                  />
                </v-list-item-avatar>
                <v-tooltip
                  right
                  content-class="account-tooltip"
                >
                  <template #activator="{ on }">
                    <v-list-item-content v-on="on">
                      <v-list-item-title
                        v-text="accountName(userData.username, userData)"
                      />
                    </v-list-item-content>
                  </template>
                  <span>{{ accountName(userData.username, userData) }}</span>
                </v-tooltip>
              </v-list-item>
              <v-subheader>Accounts</v-subheader>
              <v-list-item
                v-for="item in userData.accounts"
                :key="item.username"
                @click="itemChange(item)"
              >
                <v-list-item-avatar>
                  <v-gravatar
                    :email="item.email || item.mail || ''"
                    alt="Nobody"
                    :size="80"
                    default-img="mm"
                  />
                </v-list-item-avatar>
                <v-tooltip
                  right
                  content-class="account-tooltip"
                >
                  <template #activator="{ on }">
                    <v-list-item-content v-on="on">
                      <v-list-item-title v-text="accountName(item.username, item)" />
                    </v-list-item-content>
                  </template>
                  <span>{{ accountName(item.username, item) }}</span>
                </v-tooltip>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <component
            :is="whichComponent"
            v-model="editingItem"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions>
      <v-spacer />
      <v-btn
        small
        :disabled="!selectedAccount.accountId"
        color="warning"
        @click="deleteAccount(selectedAccount)"
      >
        Elimina account
      </v-btn>
      <v-btn
        small
        v-if="activeClientApp.can_add_account"
        color="success"
        @click="askAccount"
      >
        Aggiungi account
      </v-btn>
      <v-btn
        small
        color="accent"
        @click="cancel"
      >
        chiudi
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { accountName, fullName } from './accounts.js'
import AccountDetail from './AppUsersDetailAccount.vue'
import UserDetailUser from './AppUsersDetailUser.vue'
export default {
  data () {
    return {
      userChanged: 0,
      valid: false,
      isEditingUser: false,
      selectedAccount: { __key: null },
      selectedAccountIndex: 0,
      userData: null
    }
  },
  computed: {
    ...mapState('appAdmin', ['activeClientApp']),
    title () {
      const full = fullName(this.userData)
      if (full) return `${full} (${this.userData.username})`
      return this.userData.username
    },
    whichComponent () {
      if (this.isEditingUser) return UserDetailUser
      return AccountDetail
    },
    editingItem: {
      get () {
        if (this.isEditingUser) return this.userData
        return this.userData.accounts[this.selectedAccountIndex - 1]
      },
      set (val) {
        if (this.isEditingUser) {
          this.userData = Object.assign(this.userData, val)
          this.userChanged += 1
        } else {
          this.userChanged += 1
          this.userData.accounts[this.selectedAccountIndex - 1] = Object.assign(this.userData.accounts[this.selectedAccountIndex - 1], val)
        }
      }
    }
  },
  monuted () {
    global.coso = this
  },
  methods: {
    accountName,
    itemChange (item) {
      this.isEditingUser = false
      this.selectedAccount = Object.assign({}, item)
    },
    editUser () {
      this.isEditingUser = true
      this.selectedAccount = this.userData
    },
    loadUserDetails (user) {
      if (!user) return
      this.$http.get(`/api/app_admin/app/${this.activeClientApp.app_id}/aau/${user.id}/`)
        .then(r => {
          this.userData = r.data
          this.userData.originalMail = r.data.email
          this.userData.originalPhone = r.data.phone
          this.editUser()
        })
    },
    async saveUserDeta (user) {
      if (!user) return
      return await this.$http.patch(`/api/app_admin/app/${this.activeClientApp.app_id}/aau/${user.id}/`, user)
        .then(r => {
          this.userData = r.data
          this.msgInfo('Dati modificati con successo')
          return true
          /* this.editUser() */
        })
        .catch(e => {
          this.$msgError(e)
          return false
        })
    },
    async askAccount () {
      const res = await this.$dialog.prompt({
        title: `Aggiungi un account all'utente ${this.userData.username}`,
        text: 'Digita il nome dell\'account per il qualre richiedere info',
        type: 'info',
        icon: 'mdi-info',
        class: ['sso-dash-prompt-dialog'],
        actions: {
          false: {
            outlined: true,
            color: 'primary',
            text: 'Annulla'
          },
          true: {
            outlined: true,
            color: 'success',
            text: 'Invia'
          }
        }
      })
      if (res) {
        await this.$http.get(`/api/app_admin/app/${this.activeClientApp.app_id}/aau/${this.userData.user_id}/ask_account/`,
          { params: { username: res } })
          .then(async r => {
            let table = '<table class="info-table"><tbody>'
            Object.entries(r.data).forEach(([key, value]) => {
              table += `<tr><td>${key}</td><td>${value}</td></tr>`
            })
            table += '</tbody></table>'
            const add = await this.$dialog.confirm({
              title: `Dati per ${res}`,
              text: table,
              actions: {
                false: {
                  outlined: true,
                  color: 'primary',
                  text: 'Annulla'
                },
                true: {
                  outlined: true,
                  color: 'success',
                  text: 'Aggiungi'
                }
              }
            })
            if (add) {
              await this.$http.post(`/api/app_admin/app/${this.activeClientApp.app_id}/aau/${this.userData.user_id}/register_account/`, {
                accountName: res,
                username: this.userData.username,
                user_id: this.userData.user_id
              }).then(r => {
                this.userData = r.data
                this.$emit('reload')
                this.$msgInfo('Account aggiunto con successo.')
                return true
              }).catch(e => {
                this.$msgError(e)
                return true
              })
            }
            return true
          })
          .catch(e => {
            this.$msgError(e)
            return true
          })
      }
    },
    async deleteAccount (item) {
      const res = await this.$dialog.prompt({
        title: `Eliminare l'account "${item.username}" per l'utente "${this.userData.username}" ?`,
        text: 'Digita il nome dell\'account per confermare l\'eliminazione',
        type: 'warning',
        icon: 'mdi-alert',
        class: ['sso-dash-prompt-dialog'],
        actions: {
          false: {
            outlined: true,
            color: 'primary',
            text: 'Annulla'
          },
          true: {
            outlined: true,
            color: 'error',
            text: 'Elimina'
          }
        }
      })
      if (res === item.username) {
        this.$http.delete(`/api/app_admin/app/${this.activeClientApp.app_id}/aau/${this.userData.user_id}/remove_account/${item.accountId}/`)
          .then(response => {
            this.userData = response.data
            this.selectedAccount = { __key: null }
            this.selectedAccountIndex = 0
            this.$emit('reload')
          })
      } else if (res) {
        this.$dialog.warning({
          text: 'l\'username non corrisponde.',
          title: 'App User',
          icon: 'mdi-alert',
          type: 'warning'
        })
      }
    },
    save () {
      this.selectedAccount = { __key: null }
      this.selectedAccountIndex = 0
      this.$emit('closed')
    },
    cancel () {
      this.selectedAccount = { __key: null }
      this.selectedAccountIndex = 0
      this.$emit('closed')
    }
  }
}
</script>
<style lang="scss">
  .sso-dash-prompt-dialog {
    .v-card__title{
      word-break: break-word;
    }
  }
  .account-tooltip {
    border-radius: 10px;
  }
  .app-user-form {
    max-height: calc( 100vh - 356px );
    min-height: calc( 100vh - 356px );
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
