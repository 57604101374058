<template>
  <v-form
    v-model="valid"
    :key="item.__key"
  >
    <v-row
      class="mr-1"
    >
      <v-col
        v-for="(field, idx) in sortedFields"
        md="4"
        sm="4"
        :key="idx"
      >
        <component
          :is="checkType(field)"
          v-model="editedAccount[field.name]"
          :label="field.name"
          :read-only="field.readOnly"
        >
          <template #append>
            <v-icon
              v-text="'fas fa-pen-square'"
              color="primary"
              @click="editField(field)"
              v-show="field.editable"
            />
            <v-icon
              v-text="'fas fa-minus-square'"
              color="danger"
              @click="removeField(idx)"
              v-show="!field.readOnly && (idx || ( !idx && sortedFields.length > 1))"
            />
            <v-icon
              v-text="'fas fa-plus-square'"
              color="success"
              @click="addField(field)"
              v-show="idx == sortedFields.length - 1"
            />
          </template>
        </component>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer />
      <v-btn
        small
        color="accent"
        @click="cancel"
      >
        annulla
      </v-btn>
      <v-btn
        small
        color="primary"
        @click="save"
      >
        Salva
      </v-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import { VTextField, VCheckbox } from 'vuetify/lib'
export default {
  components: {
    VTextField,
    VCheckbox
  },
  props: {
    value: {
      type: Object,
      default: () => ({ __key: null })
    }
  },
  data () {
    return {
      editedAccount: {},
      valid: false,
      keyToSkip: [
        '__key',
        'id'
      ],
      ordering: [
        'username',
        'first_name',
        'last_name',
        'description',
        'email',
        'default'
      ]
    }
  },
  computed: {
    visibleFields () {
      if (!this?.item?.fields) return []
      return this.item.fields.filter(i => !i.hidden)
    },
    sortedFields () {
      return this.visibleFields
    },
    item: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    sortFields (a, b) {
      const idxa = this.ordering.indexOf(a.name)
      const idxb = this.ordering.indexOf(b.name)
      if (idxa === -1 && idxb === -1) return a.name.localeCompare(b.name)
      if (idxb === -1) return -1
      if (idxa === -1) return 1
      return idxa - idxb
    },
    _sortedFields () {
      return this.visibleFields.sort((a, b) => {
        const idxa = this.ordering.indexOf(a.name)
        const idxb = this.ordering.indexOf(b.name)
        if (idxa === -1 && idxb === -1) return a.name.localeCompare(b.name)
        if (idxb === -1) return -1
        if (idxa === -1) return 1
        return idxa - idxb
        // return 0
      })
    },
    async addField () {
      const key = await this.$dialog.prompt({
        text: 'Your name',
        title: 'Please input your name'
      })
      this.item.fields.push({
        name: key,
        value: '',
        type: 'string',
        readOnly: false,
        hidden: false,
        editable: true
      })
      // this.$nextTick(() => { this.$forceUpate() })
    },
    removeField (field) {
      if (field.readOnly) return
      this.items.fileds = this.items.fileds.filter(f => f.name !== field.name)
    },
    editField (key) {
    },
    itemChange (...args) {
      console.log('args', args)
    },
    checkType (field) {
      switch (field.type) {
        case 'boolean':
          return 'v-checkbox'
        default:
          return 'v-text-field'
      }
    },
    initAccount () {
      this.editedAccount = Object.assign({}, this.item)
    },
    save () {
      this.item = Object.assign({}, this.editedAccount)
      this.initAccount()
    },
    cancel () {
      this.initAccount()
    }
  },
  mounted () {
    global.udetail = this
    this.initAccount()
  },
  watch: {
    'item' () {
      this.initAccount()
    }
  }
}
</script>
